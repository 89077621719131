<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          Leverandører
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          label="Søg"
          hide-details
          class="mr-4"
          autofocus
          solo
          placeholder="Skriv for at søge"
          dense
          clearable
        />

        <v-menu
          v-model="addMenu"
          left
          :close-on-content-click="false"
          min-width="500"
        >
          <template #activator="{ attrs, on }">
            <v-btn
              v-bind="attrs"
              v-on="on"
            >
              Tilføj
              <v-icon right> mdi-plus </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              Ny leverandør
            </v-card-title>

            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
              >
                <v-text-field
                  v-model="newItem.title"
                  label="Navn"
                  :rules="[ v => !!v || 'Udfyld et navn' ]"
                />
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                text
                @click="clear"
              >
                Annuller
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                :disabled="!valid"
                @click="add"
              >
                Tilføj
                <v-icon right> mdi-plus </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :items="suppliers"
          :headers="headers"
          :loading="loading"
          :sort-by="[ 'active', 'partDetailCount' ]"
          :sort-desc="[ true, true ]"
          :search.sync="search"
          multi-sort
          :items-per-page="-1"
        >
          <template #[`item.webUrl`]="{ item }">
            <a :href="item.webUrl" target="_blank" :class="$vuetify.theme.dark ? 'white--text' : null">
              {{ item.webUrl }}
            </a>
          </template>
          <template #[`item.webLogin`]="{ item }">
            <code v-if="item.webLogin">
              {{ item.webLogin }}
            </code>
          </template>
          <template #[`item.webPassword`]="{ item }">
            <code v-if="item.webPassword">
              {{ item.webPassword }}
            </code>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-5">
              <v-row>
                <v-col>
                  <div class="font-weight-bold">Kommentarer</div>
                  {{ item.comments }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="font-weight-bold">Import konfiguration</div>
                  <pre>{{ item.partImporterConfig }}</pre>
                </v-col>
              </v-row>
            </td>
          </template>
          <template #[`item.active`]="{ item }">
            <v-simple-checkbox
              v-model="item.active"
              disabled
            />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn icon :to="item.id" append>
              <v-icon> mdi-pencil-outline </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Suppliers',
  data() {
    return {
      addMenu: false,
      creating: false,
      loading: false,
      headers: [
        { text: 'Navn', value: 'title' },
        { text: 'Ordreproces', value: 'orderProcess' },
        { text: 'Website', value: 'webUrl', sortable: false },
        { text: 'Betalinsbetingelser', value: 'paymentTerms' },
        { text: 'Varer', value: 'partDetailCount', align: 'end' },
        { text: 'Katalog', value: 'supplierPartCount', align: 'end' },
        { text: 'Aktiv', value: 'active', align: 'end' },
        { text: '', value: 'actions', sortable: false, align: 'end' }
      ],
      newItem: {
        title: ''
      },
      suppliers: [],
      valid: false,
    }
  },
  computed: {
    search: {
      get() {
        return this.$route.query.search
      },
      set(value) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: value ?? undefined
          }
        })
      }
    }
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async add() {
      try {
        this.creating = true;
        const newItemTitle = this.newItem.title;
        const response = await this.$AuthService.api.post('supplier', this.newItem);

        if (!response.data.success) {
          throw 'Error creating supplier!';
        }

        this.$notifier.showSuccess({ message: 'Leverandør blev tilføjet' });
        this.clear();
        await this.fetchData();
        this.search = newItemTitle;
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Der skete en fejl. Prøv venligst igen.' });
      } finally {
        this.creating = false;
      }
    },
    clear() {
      this.addMenu = false;
      this.newItem = {
        title: '',
        aliases: []
      };
    },

    async fetchData() {
      try {
        this.loading = true;

        const response = await this.$AuthService.api.get('supplier');

        this.suppliers = response.data;
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
